.stuff {
  display: flex;
  justify-content: space-between;
  gap: 150px;
}

@media (max-width: 1025px) {
  .stuff {
    gap: 30px;
  }
}

@media (max-width: 1025px) {
  .stuff {
    flex-direction: column;
    gap: 40px;
  }
}
