.title {
  margin-bottom: 20px;
}

.block {
  display: flex;
  gap: 80px;
  justify-content: space-between;
}

.text {
  &_first {
    width: 100%;
  }

  &_sec {
    max-width: 430px;
    width: 100%;
  }
}

@media (max-width: 1025px) {
  .block {
    flex-direction: column;
    gap: 20px;
  }
}
