.block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 22px;
}

.info {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    max-width: 225px;
}

.ico {
    width: 177px;
    height: 177px;
    flex-shrink: 0;
    object-fit: contain;
}

.title {
    font-size: 22px;
    margin-bottom: 20px;
}

.text {
    font-size: 16px;
    &:not(:last-child) {
        margin-bottom: 14px;
    }
}

@media(max-width: 1025px) {
    .block {
        flex-direction: column;
    }

    .ico {
        width: 132px;
        height: 132px;
    }

    .info {
        text-align: center;
    }

    .title {
        font-size: 16px;
        margin-bottom: 13px;
    }

    .text {
        font-size: 13px;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}