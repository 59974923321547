.categories {
  &__inner {
    overflow: auto;
  }

  &__title {
    margin-bottom: 42px;
  }
  &__block {
    &-outer {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 25px;
      width: max-content;
    }

    display: flex;
    flex-direction: column;

    border-radius: 10px;
    border: var(--block-b);
    color: var(--dark);
    width: 320px;
    height: 300px;
    padding: 34px 0 45px 34px;

    transition: color 0.15s linear, font-weight 0.15s linear,
      border-color 0.15s linear, border-width 0.15s linear;

    @media (any-hover: hover) {
      &:hover {
        color: var(--accent-hover);
        border-color: var(--accent-hover);
        border-width: 2px;
      }
    }

    &:active {
      color: var(--accent-active);
      border-color: var(--accent-active);
    }

    &-title {
      flex: 1 0 auto;
      font-size: 16px;
      font-weight: 400;

      max-width: 200px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-img {
      display: flex;
      flex: 0 0 auto;
      margin-left: auto;
    }
  }
}

@media (max-width: 1025px) {
  .categories {
    &__title {
      margin-bottom: 20px;
    }
    &__inner {
      width: calc(100% + 15px);
      padding-bottom: 30px;
    }
    &__block {
      &-outer {
        padding-right: 15px;
        grid-template-columns: repeat(6, 1fr);
      }

      padding: 12px 14px;
      width: 260px;
      height: 245px;

      &-img {
        width: 180px;
      }
    }
  }
}

@media (max-width: 1025px) {
}
