.sign {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__logo {
    width: 167px !important;
    margin-bottom: 42px;
  }

  &__input {
    margin-bottom: 12px;
  }

  &__btn {
    max-width: 100%;
    width: 100%;
    font-size: 16px;
  }

  &__repeat {
    padding: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;
    color: var(--accent);
    gap: 5px;
    @media (any-hover: hover) {
      &:hover {
        color: var(--accent-hover);
      }
    }

    &:active {
      color: var(--accent-active);
    }
    &.dis {
      color: var(--el-dis-c);
      pointer-events: none;
    }
  }

  &__submit {
    margin-top: 30px;
  }

  &__other {
    margin-top: 26px;
    &-or {
      margin-bottom: 26px;
      color: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 16px;
      font-size: 14px;
      line-height: 18px;

      &:before,
      &:after {
        content: '';
        display: block;
        height: 1px;
        width: 70px;
        background: currentColor;
      }
    }

    &-btns {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &__google,
  &__apple {
    width: 100%;
    max-width: 100%;
    gap: 10px;
    font-weight: 700;
  }

  &__google {
    background: #f6f7fa;
    color: #5f5f5f;
    @media (any-hover: hover) {
      &:hover {
        background: #d9dadd;
        color: #5f5f5f;
      }
    }
    &:active {
      background: #c3c4c7;
      color: #5f5f5f;
    }
  }

  &__apple {
    background: #1e1e1e;
    color: var(--light);
    @media (any-hover: hover) {
      &:hover {
        background: #151414;
      }
    }
    &:active {
      background: #060505;
    }
  }
}

@media (max-width: 1025px) {
}
@media (max-width: 1025px) {
  .sign {
    padding-top: 30px;
    padding-bottom: 30px;
    &__input {
      margin-bottom: 8px;
    }

    &__input {
      border-width: 1px;
    }

    &__repeat {
      font-size: 12px;
    }

    &__input,
    &__btn {
      font-size: 12px;
      padding: 12px 15px;
    }
  }
}
