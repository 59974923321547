.card {
  border: 1px solid #d9d9d9;
  color: var(--dark);
  transition: border-color 0.15s linear;
  border-radius: 10px;
  overflow: hidden;
  @media (any-hover: hover) {
    &:hover {
      border-color: var(--accent-hover);

      .btn {
        background: var(--accent-hover);
        color: var(--light);
      }
    }
  }

  &:active {
    border-color: var(--accent-active);
    .btn {
      background: var(--accent-active);
      color: var(--light);
    }
  }
}

.img {
  display: flex;
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.info {
  padding: 25px;
}

.title {
  margin-bottom: 14px;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.rating {
  margin-bottom: 14px !important;
}

.text {
  margin-bottom: 30px;
  color: #5f5f5f;
  font-size: 11px;
  font-weight: 400;
  line-height: 140%;

  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.btn {
  font-size: 12px;
  gap: 8px;
}

@media (max-width: 1025px) {
  .card {
    padding: 0 !important;
  }

  .img {
    height: 135px;
  }

  .info {
    padding: 14px;
  }

  .title {
    font-size: 12px !important;
    min-height: 32px !important;
    height: 32px !important;
    margin-bottom: 5px;
  }

  .rate {
    display: none;
  }

  .star {
    width: 14px;
    height: 14px;
  }

  .text {
    margin-bottom: 14px;
  }
}
