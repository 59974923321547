.crumbs {
  display: flex;
  margin-bottom: 30px;
  &__item {
    display: flex;
    gap: 10px;
    margin-right: 10px;

    &-hidden {
      display: none;
    }

    &-pointless {
      pointer-events: none;
    }

    &:not(:last-child) {
      &:after {
        content: '/';
        color: var(--light-gray);
      }
    }

    &:last-child {
      pointer-events: none;
      .crumbs__link {
        color: var(--search-ph);
      }
    }
  }

  &__link {
    font-weight: 400;
  }
}

@media (max-width: 1025px) {
  .crumbs {
    padding-top: 15px;
    &__link {
      font-size: 12px;
    }
  }
}
