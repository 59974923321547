.overlay {
  padding: 15px;
}

.modal {
  padding: 40px;
  border-radius: 12px;
  max-width: 660px;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

@media (max-width: 1025px) {
  .modal {
    padding: 25px;
  }

  .inner {
    gap: 20px;
  }
}
