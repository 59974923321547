.outer {
  position: relative;
}

.slider {
  min-width: 0;
  min-height: 0;
  background: var(--light);
  width: 188px;
  height: 574px;
  flex-shrink: 0;
}

.nav {
  position: static;
}

.prev,
.next {
  position: absolute;
  z-index: 1;
  left: 0;
  max-width: 100% !important;
  width: 100% !important;
  height: 20px !important;
  border: unset !important;
  border-radius: 8px !important;
}

.prev {
  bottom: calc(100% + 10px);
  transform: translate(0, 0) rotate(0) !important;

  svg {
    transform: rotate(-90deg);
  }
}

.next {
  top: calc(100% + 10px);
  transform: translate(0, 0%) !important;
  svg {
    transform: rotate(90deg);
  }
}

.active {
  > .content {
    border-color: var(--accent) !important;
    pointer-events: none;
  }
}

.content {
  position: relative;
  border: var(--block-b);
  border-radius: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: border-color 0.2s linear;

  @media (any-hover: hover) {
    &:hover {
      border-color: var(--accent-hover);
    }
  }

  &:active {
    border-color: var(--accent-active);
  }
}
.img {
  width: 100%;
  max-height: 97%;
  object-fit: contain;
}
