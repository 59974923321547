.content {
  margin-top: 20px;
}

.sort {
  &_outer {
    display: flex;
    gap: 14px;
    margin-bottom: 60px;
  }

  &_btn {
    padding: 17px 40px;

    border-color: #ececec;
    color: var(--dark);

    @media (any-hover: hover) {
      &:hover {
        background: unset;
        color: var(--dark);
        border-color: var(--accent);
      }
    }
  }

  &:active {
    background: unset;
    color: var(--dark);
    border-color: var(--accent);
    pointer-events: none;
  }
}

.reviews {
  margin-bottom: 60px;
}

.more {
  margin: 0 auto;
  padding: 17px 52px;
}

@media (max-width: 1025px) {
  .sort_outer {
    display: none;
  }
}
