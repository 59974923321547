.mainInfo {
  width: 100%;
  max-width: 45%;
}

.uId {
  color: #5f5f5f;
  margin-bottom: 12px;
  font-size: 14px;
}

.title {
  margin-bottom: 10px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
}

.stars {
  display: flex;
  align-items: center;
  gap: 4px;
}

.rate {
  color: var(--accent);
  font-size: 16px;
  font-weight: 400;
}

.block {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 30px;

  &_title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 14px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 19px;
}

.toDescr {
  width: max-content;
  margin-bottom: 30px;
  font-weight: 400;
}

.seller {
  margin-bottom: 30px;
}

.price {
  margin-bottom: 22px;
}

.btns {
  display: flex;
  align-items: center;
  gap: 16px;
}

.btn__title {
  font-weight: 500;
  font-size: 20px;
}

.toCart {
  height: 65px;
}

.toFav {
  max-width: 65px;
  width: 65px;
  height: 65px;
  color: var(--accent);
}

@media (max-width: 1025px) {
  .mainInfo {
    max-width: 100%;
  }
}

@media (max-width: 1025px) {
  .block_title,
  .list,
  .toDescr {
    display: none;
  }

  .title {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .block {
    margin-bottom: 0;
  }

  .rating {
    margin-bottom: 37px;
  }

  .seller {
    margin-bottom: 25px;
  }

  .price {
    font-size: 20px;
    margin-bottom: 25px;
  }

  .toCart {
    max-width: 100%;
    width: 100%;
  }
}
