.outer {
  border-radius: 6px;
  border: var(--block-b);
  flex: 1 0 auto;
  height: max-content;
}

.products {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 40px 30px 20px;
}

@media (max-width: 1025px) {
  .products {
    gap: 20px;
    padding: 20px 15px;
  }
}
