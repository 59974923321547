.images {
  display: flex;
  gap: 20px;
}

.item {
  width: 140px;
  height: 140px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}

.image {
  object-fit: contain;
  border: var(--block-b);
}

.count {
  font-size: 22px;
  color: var(--accent);
  border: 1px solid currentColor;

  transition: color 0.2s linear;

  @media (any-hover: hover) {
    &:hover {
      color: var(--accent-hover);
    }
  }

  &:active {
    color: var(--accent-active);
  }
}

@media (max-width: 1025px) {
  .images {
    gap: 8px;
  }

  .item {
    width: 60px;
    height: 60px;
  }

  .count {
    font-size: 10px;
  }
}
