@media (max-width: 1025px) {
  .bar {
    position: fixed;
    z-index: 9999;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--light);
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);

    &__inner {
      max-width: 450px;
      padding: 15px 10px;
      margin: 0 auto;
    }

    &__nav {
      &-list {
        background: unset;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
      }
      &-link {
        position: relative;
        transition: color 0.2s linear;
        &.active {
          color: var(--accent);
        }

        @media (any-hover: hover) {
          &:hover {
            color: var(--accent);
          }
        }

        &:active {
          color: var(--accent);
        }

        color: #aeaeae;
        & .count {
          top: -8px;
          right: -4px;
          position: absolute;
          z-index: 1;
          background: var(--red);
          color: var(--light);
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          border-radius: 16px;
          padding: 3px;
          min-width: 15px;
          min-height: 15px;
          font-size: 8px;
        }
      }
      &-btn {
        color: currentColor;
        background: unset;
        border: unset;
        padding: 0;
        flex-direction: column;

        @media (any-hover: hover) {
          &:hover {
            background: unset;
            color: var(--accent);
          }
        }

        &:active {
          background: unset;
          color: var(--accent);
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .bar {
    &__nav {
      width: 100%;
      &-list {
        border-radius: 6px;
        gap: 4px;
        justify-content: space-between;
      }
      &-link {
        &.active {
          color: var(--accent);
        }
      }
      &-btn {
        padding-left: 6px;
        padding-right: 6px;
        gap: 3px;
        font-size: 11px;
      }
    }
  }
}
