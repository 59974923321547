.head {
  min-width: 220px;
}

.status {
  margin-bottom: 32px;
  width: max-content;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 14px;

  &_item {
    font-size: 14px;
    font-weight: 400;
  }

  &_btn {font-weight: 400;
    font-size: 12px;
    padding: 12px;
    width: 100%;
    max-width: 100%;
  }
}

@media (max-width: 1025px) {
  .status {
    margin-bottom: 22px;
  }

  .list {
    &_btn {
      font-size: 12px;
    }
  }
}
