.status {
  margin-bottom: 32px;
}

.seller {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.text {
  font-size: 14px;
}

@media (max-width: 1025px) {
  .status {
    margin-bottom: 18px;
    font-size: 10px;
  }

  .seller,
  .text {
    font-size: 10px;
  }
}
