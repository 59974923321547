.filter {
  border-radius: 10px;
  border: var(--block-b);
  padding: 28px 25px;
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 320px;

  font-size: 16px;

  > div {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      &:after {
        content: "";
        width: 100%;
        height: 1px;
        background: var(--light-gray);
        margin-top: 28px;
      }
    }
  }

  &__block {
    &-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &__star {
    &-outer {
      display: flex;
      gap: 3px;
    }

    height: 22px;
    width: 22px;
    transform: translate(0,-2px);

    color: var(--light-gray);

    &.active {
      color: var(--yellow);
    }
  }

  &__check {
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &-text {
      display: flex;
      gap: 6px;
    }
  }

  &__count {
    font-weight: 400;
    color: var(--search-ph);
  }
}
