.head {
  background: #f6f6f6;
  padding: 28px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.title {
  font-weight: 500;
  font-size: 20px;
}

.subtitle {
  color: var(--accent);
  font-size: 18px;
}

.price {
  font-size: 24px;
  font-weight: 500;
}

@media (max-width: 1025px) {
  .head {
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    gap: 18px;
  }

  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
  }

  .price {
    font-size: 14px;
  }
}
