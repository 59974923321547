.overlay {
  z-index: 1;
  display: block;
  background: rgba(10, 26, 56, 0.4);
  top: 0;
}

.modal {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: var(--container-width);
  padding: 0;
  background: transparent;
}

.inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}


.head {
    background: var(--light);
    border-bottom: 1px solid #D9D9D9;
    padding: 12px;
    min-height: 60px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    

  }

.title {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
  }

.close {
    font-size: 14px;
    font-weight: 400;
  }


.content {
    overflow: auto;
    height: calc(100dvh - 60px);
    background: var(--light);
}

.list {
  display: flex;
  flex-direction: column;
}

.item {
  width: 100%;
  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    gap: 15px;
    padding: 15px;
    font-size: 14px;
    color: var(--dark);
    font-weight: 400 !important;

    &_active {
      font-weight: 700 !important;
    }
  }
}