.side {
  max-width: 180px;
}

.list {
  &_outer {
    display: flex;
    flex-direction: column;
    gap: 26px;
    margin-bottom: 36px;
  }
  &_title {
    margin-bottom: 14px;
    font-size: 14px;
  }

  display: flex;
  flex-direction: column;
  gap: 10px;

  &_link {
    font-weight: 400;
    font-size: 14px;

    &_disabled {
      pointer-events: none;
      color: #7b7b7b;
    }

    &_active {
      color: var(--accent);
    }
  }
}

.signOut {
  font-size: 14px;
}

@media (max-width: 1025px) {
  .side {
    max-width: 100%;
  }

  .list {
    &_title {
      display: none;
    }

    border-radius: 6px;
    border: var(--block-b);

    &_link {
      width: 100%;
      padding: 15px 8px 15px 20px;
      justify-content: space-between;
    }
  }

  .signOut {
    width: 100%;
    max-width: 100%;
    border-radius: 4px;
  }
}
