.sliders {
  display: flex;
  align-items: center;
  gap: 22px;
}

@media (max-width: 1025px) {
  .sliders {
    width: 100%;
  }
}
