.content {
  margin-top: 20px;
}

.descr {
  font-size: 16px;
  line-height: 160%;
}

@media (max-width: 1025px) {
  .descr {
    font-size: 12px;
  }
}
