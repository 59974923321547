.content {
  display: flex;
  flex-direction: column;
  gap: 65px;
  margin-top: 20px;
}

@media (max-width: 1025px) {
  .title {
    font-size: 16px;
  }

  .item {
    font-size: 13px;
  }

  .list {
    gap: 12px 15px;
    &_grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .item,
  .key,
  .val {
    width: 100%;
  }

  .val {
    text-align: right;
  }
}
