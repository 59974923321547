.tab {
    display: flex;
    flex-direction: column;
  }
  
  .btn {
    padding: 20px 70px;
    color: var(--accent);
    background: transparent;
    position: relative;
    z-index: 1;
  
    transition: background 0.2s linear, color 0.2s linear;
    &_outer {
      position: relative;
      z-index: 1;
      &:after {
        content: '';
        z-index: -1;
        position: absolute;
        left: 0;
        top: calc(100% - 1px);
        background: #efefef;
        width: 100%;
        height: 1px;
      }
    }
    &_active {
      background: #efefef;
      color: var(--dark);
      pointer-events: none;
    }
  }
  
  @media (max-width: 1025px) {
    .btn {
      font-size: 12px;
      padding: 20px 40px;
      white-space: nowrap;
      &_outer {
        display: flex;
        overflow: auto;
      }
    }
  }
  