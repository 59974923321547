.modal {
    border-radius: 6px;
    padding: 44px 60px;
}

.inner {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 28px;
}