.wrapper {
  margin-bottom: 50px;
}

.title {
  margin-bottom: 40px;
}

.block {
  &_outer {
    display: flex;
    gap: 15px;
    justify-content: space-between;
  }

  border-radius: 8px;
  background: #f1f6ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding: 35px 18px;

  &_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 145%;
  }

  &_text {
    font-size: 14px !important;
    line-height: 160%;
  }
}

@media (max-width: 1025px) {
  .title {
    text-align: center;
    margin-bottom: 24px;
  }

  .block {
    &_outer {
      flex-direction: column;
      align-items: center;
    }

    max-width: 260px;
    width: 100%;
  }
}
