.head {
  margin-bottom: 30px;
}

.goBack {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 30px;
}

.title {
  margin-bottom: 6px;
}

.date {
  font-size: 14px;
}
