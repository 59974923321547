.items {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 1025px) {
  .items {
    gap: 20px;
  }
}
