.outer {
  position: relative;
}

.slider {
  min-width: 0;
  border-radius: 10px;
  border: var(--block-b);
  background: var(--light);
  width: 534px;
  height: 644px;
  flex-shrink: 0;
}

.nav {
  position: static;
}

.prev,
.next {
  position: absolute;
  z-index: 1;
  top: 50%;
}

.prev {
  left: 27px;
  transform: translate(0, -50%) rotate(180deg) scale(0.8) !important;
}

.next {
  right: 27px;
  transform: translate(0, -50%) scale(0.8);
}

.content {
  position: relative;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.scaleText {
  position: absolute;
  z-index: 2;
  top: 18px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

.pagination {
  display: none;
}

.img {
  width: 100%;
  max-width: 400px;
  max-height: 500px;
  object-fit: contain;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1025px) {
  .outer {
    width: 100%;
  }

  .slider {
    width: 100%;
  }

  .pagination {
    top: unset !important;
    left: 50% !important;
    bottom: 16px !important;
    transform: translate(-50%, 0);
    position: absolute;
    display: flex;
    align-items: center;
    gap: 9px;
    width: max-content !important;
  }
  .bullet {
    margin: 0 !important;
    width: 7px;
    height: 7px;

    background-color: var(--light-gray);
    border-radius: 7px;

    transition: width 0.5s linear, background-color 0.5s linear;
    &.active {
      width: 20px;
      background-color: var(--accent);
    }
  }
}

@media (max-width: 1025px) {
  .slider {
    height: calc(100vw - 30px + 50px);
  }

  .scaleText {
    display: none;
  }
}
