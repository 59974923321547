.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--light);
  z-index: 10;

  &__inner {
    display: flex;
    flex-direction: column;
    background: var(--light);
    border-bottom: 1px solid #d9d9d97d;
  }

  &__top {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 14px;
    &:before {
      content: '';
      z-index: -1;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      width: 3000px;
      height: 100%;
      background: rgba(#cfe1ff, 0.3);
    }

    &-contacts {
      display: flex;
      gap: 24px;
    }

    &-pages {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    &-link {
      color: #808a9c;
      transition: background 0.15s linear, color 0.15s linear,
        border-color 0.15s linear;

        &-active {
          color: var(--accent);
        }
    }

    &-btn {
      border-radius: 66px;
      padding: 5px 12px;
      color: #808a9c;
      background: #dee9fe;
      transition: background 0.15s linear, color 0.15s linear,
        border-color 0.15s linear;
      @media (any-hover: hover) {
        &:hover {
          background: var(--accent-hover);
          color: var(--light);
        }
      }

      &:active {
        background: var(--accent-active);
        color: var(--light);
      }
    }
  }

  &__bot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 45px;
    padding: 20px 0;
    background: var(--light);
  }

  &__catalog-search {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;

    &-link {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--dark);
      width: max-content;
      transition: color 0.2s linear;
      font-weight: 500;
      font-size: 14px;
      @media (any-hover: hover) {
        &:hover {
          color: var(--accent-hover);
        }
      }

      &:active {
        color: var(--accent-active);
      }

      &.active {
        color: var(--accent);
        pointer-events: none;
      }

      & .count {
        top: -8px;
        right: -8px;
        position: absolute;
        z-index: 1;
        background: var(--red);
        color: var(--light);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        padding: 3px;
        min-width: 16px;
        min-height: 16px;
        font-size: 9px;
      }
    }
  }

  &__search {
    &-modal {
      &-overlay {
        position: absolute !important;
        top: 140px !important;
        z-index: -1 !important;
        align-items: flex-start !important;
        background: rgba(10, 26, 56, 0.4) !important;
      }

      padding: 15px 0 !important;
      width: 100% !important;
      height: auto;
      max-height: 70%;
      overflow: auto;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      &-notFound {
        padding: 15px 0;
      }

      &-results {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &-link {
          color: var(--dark);
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .header {
    &__logo {
      display: none;
    }
    &__inner {
      border-bottom: unset;
    }
    &__top {
      display: none;
    }
    &__bot {
      gap: 20px;
      border-bottom: unset;
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &__catalog {
      display: none;
    }

    &__search {
      &-modal {
        &-overlay {
          top: 72px !important;
        }

        top: 0 !important;
      }
    }

    &__list {
      &-outer {
        display: none;
      }
      &-item {
        &:not(.header__list-chats) {
          display: none;
        }
      }

      &-chats {
        span:not(.count) {
          display: none;
        }

        .count {
          right: -11px;
          top: -11px;
          font-size: 7px;
        }
      }
    }
  }
}
