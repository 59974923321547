.block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form {
  margin-left: 38px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.fields {
  display: grid;
  max-width: 440px;
  gap: 20px 80px;
  &_text {
    grid-template-columns: repeat(2, 1fr);
  }
  &_input {
    grid-template-columns: repeat(1, 1fr);
  }
}

.field {
  &_outer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &_title {
    color: #5f5f5f;
    font-size: 12px;
  }

  &_text {
    border: unset;
    pointer-events: none;
    padding: 0;
    font-size: 14px;
  }

  &_tel {
    color: #7b7b7b;
  }
}

.btn {
  &_outer {
    display: flex;
    gap: 20px;
  }
  &_link {
    width: max-content;
  }
}

@media (max-width: 1025px) {
  .fields {
    &_text {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
