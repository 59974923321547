.inner {
  display: flex;
  gap: 20px 80px;
}

@media (max-width: 1025px) {
  .inner {
    flex-direction: column;
    gap: 20px;
  }

  .modal {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    z-index: 1000;
    background: var(--light);

    padding: 90px 15px 0;

    display: none;
    flex-direction: column;

    &_active {
      display: flex;
    }
  }

  .head {
    background: var(--light);
    border-bottom: var(--block-b);
    padding: 12px;
    position: fixed;
    width: 100%;
    min-height: 60px;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;

    &_title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 14px;
    }
  }

  .goBack {
    font-size: 14px;
    font-weight: 400;
    color: var(--accent);
  }
}
