.overlay {
  z-index: -1;
  align-items: flex-start;
  background: rgba(10, 26, 56, 0.4);
  top: 141px;
  padding-top: 15px;
}

.modal {
  width: 100%;
  margin: 0 auto;
  max-width: var(--container-width);
  padding: 0;
  background: transparent;
}

.inner {
  display: flex;
  max-height: 500px;
}

.list {
  background: var(--light);
  border-radius: 10px;
  width: 337px;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow-x: hidden;
  overflow-y: auto;

  &__active {
    border-right: 1px solid #d9d9d9;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    padding: 18px 20px;
    cursor: pointer;
    & > svg {
      color: #cccccc;
    }

    &_active {
      background: #f6f6f6;

      & > svg {
        color: var(--accent);
      }
    }
  }
}

.blocks {
  display: none;
  width: 100%;
  padding: 18px 30px;
  background: var(--light);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: 15px;
  overflow-x: hidden;
  overflow-y: auto;

  &__active {
    display: block;
  }

  &__inner {
    max-width: 750px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    gap: 60px;
  }
}

.block {
  display: flex;
  flex-direction: column;
  &__title {
    margin-bottom: 20px;
    font-size: 15px;
    justify-content: flex-start;
    width: max-content;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__link {
    color: #737373;

    &_active {
      color: var(--accent);
    }
  }
}

@media (max-width: 1025px) {
  .overlay {
    display: none;
  }
}
