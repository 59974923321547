.item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 140px;

  padding-bottom: 30px;
  border-bottom: var(--block-b);

  &:not(:last-child) {
    margin-bottom: 40px;
  }
}

.info {
  display: flex;
  flex-direction: column;
}

.stars {
  display: flex;
  gap: 4px;
  margin-bottom: 10px;
}

.user {
  font-size: 16px;
  margin-bottom: 2px;
}

.date {
  font-size: 16px;
  color: #5f5f5f;
}

.text {
  font-size: 16px;
}

@media (max-width: 1025px) {
  .item {
    flex-direction: column;
    gap: 24px;
  }

  .info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }
}

@media (max-width: 1025px) {
  .text {
    font-size: 13px;
  }
}
