.title {
    margin-bottom: 20px;
}

.item {
    display: flex;
    gap: 30px;
}

.img {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
    object-fit: cover;
    border-radius: 6px;
}

.price {
    margin-bottom: 10px;
    font-size: 14px;
}

.name {
    font-size: 16px;
    margin-bottom: 8px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    &__item {
        font-size: 12px;
        color: #5f5f5f;
    }
}

@media(max-width: 1025px) {
    .title {
        display: none;
    }
    .item {
        gap: 14px;
    }

.img {
    width: 60px;
    height: 60px;
}

.price, .name{
    font-size: 12px;
    margin-bottom: 4px;
}

.list__item {
    font-size: 10px;
}
}