.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.check {
  margin-top: unset !important;
  margin-bottom: unset !important;
  margin-right: 45px !important;

  &__custom {
    margin: unset !important;
  }
}

.img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-right: 30px;
}

.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.texts {
  max-width: 295px;
  width: 100%;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.item {
  color: #5f5f5f;
  font-size: 12px;
}

.count {
  display: flex;
  align-items: center;
  height: max-content;
  width: max-content;
  border: var(--block-b);
  border-radius: 6px;
  > span {
    padding: 5px;
    font-weight: 500;
  }
}

.dec,
.inc {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  color: var(--accent);
  transition: color 0.15s linear;

  @media (any-hover: hover) {
    &:hover {
      color: var(--accent-hover);
    }
  }

  &:active {
    color: var(--accent-active);
  }
  &.dis {
    color: var(--light-gray);
    pointer-events: none;
  }
}

.dec {
  padding-left: 9px;
}

.inc {
  padding-right: 9px;
}

.output {
  display: flex;
  flex-direction: column;
}

.price {
  font-size: 16px;
  font-weight: 500;
}

.btns {
  flex: 0 0 auto;
  margin-left: auto;
  margin-top: auto;
  display: flex;
  gap: 16px;
}

.fav,
.del {
  border: unset;
  padding: unset;
  color: var(--accent);
}

.fav {
  @media (any-hover: hover) {
    &:hover {
      background: unset;
      color: var(--accent-hover);
    }
  }

  &:active {
    color: var(--accent-active);
    background: unset;
  }
}

.del {
  @media (any-hover: hover) {
    &:hover {
      background: unset;
      color: var(--red-hover);
    }
  }

  &:active {
    color: var(--red-active);
    background: unset;
  }
}

@media (max-width: 1025px) {
  .info {
    flex-direction: column;
  }

  .price {
    font-weight: 400;
    margin-bottom: 10px;
  }

  .btns {
    margin-left: unset;
  }
}

@media (max-width: 1025px) {
  .product {
    align-items: flex-start;
  }
  .check {
    margin-right: 12px !important;
    margin-top: 20px !important;
    margin-bottom: 0 !important;
  }
  .img {
    margin-right: 10px;
    width: 60px;
    height: 60px;
  }

  .price {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .title {
    font-size: 12px;
    margin-bottom: 4px;
  }

  .list {
    gap: 2px;
    margin-bottom: 10px;
  }

  .count {
    margin-bottom: 12px;
    > span {
      font-size: 12px;
    }
  }

  .dec,
  .inc {
    padding: 11px;
  }

  .dec {
    padding-left: 7px;
  }

  .inc {
    padding-right: 7px;
  }

  .fav,
  .del {
    height: 20px;
    width: 20px;

    svg {
      width: inherit !important;
      height: inherit !important;
    }
  }
}
