.notFound {
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  &__title {
    color: var(--accent);
    font-size: 200px;
    line-height: 100%;
    margin-bottom: 30px;
  }

  &__subtitle {
    margin-bottom: 20px;
    line-height: 160%;
    max-width: 630px;
  }

  &__btn {
    padding: 17px 30px;
  }
}

@media (max-width: 1025px) {
  .notFound {
    &__inner {
      height: calc(100svh - 143px - 120px);
    }
    &__title {
      font-size: 100px;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 12px;
      max-width: 284px;
    }

    &__btn {
      width: 100%;
      max-width: 100%;
    }
  }
}
