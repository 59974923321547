.block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text {
  &_first {
    max-width: 680px;
    width: 100%;
  }
}

.btn {
  padding: 20px 86px;
}

@media (max-width: 1025px) {
  .block {
    flex-direction: column;
    align-items: flex-start;
  }

  .text_first {
    margin-bottom: 25px;
  }

  .text_sec {
    margin-bottom: 16px;
  }

  .btn {
    width: 100%;
    max-width: 100%;
  }
}
