.top {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 70px;
}

// .table {
//   & table {
//     max-width: max-content;
//     width: 100%;
//   }

//   & tbody {
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//   }

//   & tr {
//     display: flex;
//     justify-content: space-between;
//     gap: 20px;
//   }

//   & td {
//     width: auto !important;
//     font-size: 14px;
//   }

//   & td:first-child {
//     color: #5f5f5f;
//     & span {
//       font-family: var(--font-family);
//     }
//   }

//   & td:last-child {
//     text-align: right;
//     & span {
//       font-weight: 500;
//       font-family: var(--font-family);
//     }
//   }
// }

table {
  width: 100% !important;
  height: auto !important;
}

@media (max-width: 1025px) {
  .top {
    flex-direction: column;
    gap: 20px;
  }
}
