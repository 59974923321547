.alert {
  pointer-events: none;
  border-radius: 8px;
  color: #5f5f5f;
  background: #f6f7fa;
  width: 100%;
  max-width: 100%;
  align-items: flex-start;
  > span {
    max-width: 243px;
    font-size: 12px;
  }
}

.ico {
  min-width: 30px;
}

@media (max-width: 1025px) {
  .alert {
    padding: 9px 20px;
    > svg {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
    }
    > span {
      font-size: 10px;
    }
  }
}
