.block {
  display: flex;
  justify-content: space-between;
  gap: 60px;
}

.text {
  &_first {
    max-width: 680px;
    width: 100%;
  }

  &_sec {
    min-width: 135px;
  }
}

.btn {
  padding: 20px 86px;
  text-align: center;
  width: 100%;
  height: max-content;
}

@media (max-width: 1025px) {
  .block {
    flex-direction: column;
    gap: 0;
  }

  .text_first {
    margin-bottom: 25px;
  }

  .text_sec {
    margin-bottom: 16px;
  }

  .btn {
    width: 100%;
    max-width: 100%;
    padding: 20px 15px;
  }
}
