.wrapper {
  margin-bottom: 50px;
}

.title {
  margin-bottom: 20px;
}

.block {
  &_outer {
    display: flex;
    gap: 15px;
    justify-content: space-between;
  }

  border-radius: 8px;
  background: #f1f6ff;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 35px 18px;
}

.ico {
  width: 76px;
  height: 76px;
  object-fit: contain;
}

.text {
  font-size: 14px;
  line-height: 160%;
}

@media (max-width: 1025px) {
  .title {
    margin-bottom: 24px;
  }

  .block {
    &_outer {
      flex-direction: column;
      align-items: center;
    }
  }
}
