.block {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background: #f1f6ff;
  border-radius: 12px;
  padding: 8px 15px 32px;
}

.title {
  font-weight: 500;
  font-size: 128px;
  letter-spacing: -0.05em;
  font-family: var(--second-ff);
  color: var(--accent);
}

.subtitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
}

@media (max-width: 1025px) {
  .block {
    gap: 9px;
  }

  .title {
    font-size: 64px;
  }

  .subtitle {
    max-width: 210px;
    font-size: 14px;
  }
}
