.status {
  &_outer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
  }

  color: var(--light);
  padding: 5px 12px;
  border-radius: 70px;
}

.pending {
  background: var(--yellow);
}

.full {
  background: green;
}

.rej {
  background: var(--accent);
}
