.burger {
  display: none;
}

@media (max-width: 1025px) {
  .burger {
    --burger-width: 44px;
    --burger-height: 40px;
    --burger-line-height: 2px;

    display: block;
    position: relative;
    border: none;
    padding: 0;
    width: var(--burger-width);
    height: var(--burger-height);
    color: #6c6e73;
    background-color: transparent;
    cursor: pointer;
    flex-shrink: 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      height: var(--burger-line-height);
      background-color: currentColor;
      transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
      transform: translate(-50%, 0);
      border-radius: 2px;
    }

    &::before {
      left: 50%;
      top: 13px;
      width: 26px;
    }

    &::after {
      left: calc(50% + 4px);
      top: 25px;
      width: 18px;
    }

    &_line {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 26px;
      height: var(--burger-line-height);
      background-color: currentColor;
      transform: translate(-50%, -50%);
      transition: transform 0.3s ease-in-out;
      border-radius: 5px;
    }

    &_active {
      &::before {
        top: 50%;
        width: 26px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
      }

      &::after {
        top: 50%;
        width: 26px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
      }

      .burger_line {
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.3s ease-in-out;
      }
    }
  }
}
