.footer {
  &__inner {
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
  }

  &__high,
  &__low {
    padding-top: 30px;
    padding-bottom: 48px;
    border-top: var(--section-b);

    display: flex;
    justify-content: space-between;
  }

  &__high {
    align-items: center;
  }

  &__logo {
    width: 266px;
    height: 90px;
    margin-bottom: -10px;

    &-outer {
      transform: translate(0, -10px);
    }
  }

  &__rights {
    position: relative;
    left: 79px;
    font-size: 12px;
    color: var(--gray);
  }

  &__contacts {
    display: flex;
    gap: 55px;
  }

  &__tel {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &-link {
      color: var(--accent);
      font-size: 46px;
      font-weight: 400;
      line-height: 120%;
    }

    &-text {
      font-size: 12px;
      color: var(--gray);
    }
  }

  &__social {
    &-title {
      margin-bottom: 21px;
      font-size: 16px;
      font-weight: 500;
    }
    margin-top: 7px;
    display: flex;
    gap: 10px;
  }

  &__btn {
    padding: 16px 29px;
  }

  &__low-left {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    width: 100%;

    & > .footer__social-outer {
      flex: 1 0 auto;
    }
  }

  &__privacy {
    &-outer {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }

    font-weight: 400;
  }

  &__nav {
    &-outer {
      display: flex;
      width: 100%;
      max-width: 400px;
      justify-content: space-between;
    }
    &-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 21px;
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 22px;
    }

    &-link {
      font-weight: 400;
    }
  }

  &__link-brsys-outer {
    width: 100%;
    text-align: right;
    white-space: nowrap;
    margin-left: auto;
  }
}

@media (max-width: 1025px) {
  .footer {
    &__high {
      flex-wrap: wrap;
      gap: 45px;
    }

    &__contacts {
      flex-wrap: wrap;
    }

    &__low {
      flex-wrap: wrap;
      gap: 45px;

      &-left {
        gap: 45px;
        width: auto;
      }
    }

    &__link {
      &-brsys {
        &-outer {
          text-align: left;
          margin-left: 0;
          width: auto;
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .footer {
    &__container {
      width: auto;
    }
    &__inner {
      padding-bottom: 130px;
    }

    &__logo {
      &-outer {
        position: relative;
      }

      width: 242px;
      height: 100px;
    }
    &__rights {
      position: absolute;
      bottom: 0;
    }

    &__contacts {
      gap: 30px;
      padding-left: 80px;
    }

    &__tel {
      &-link {
        font-size: 25.067px;
      }
    }

    &__btn {
      width: 100%;
      max-width: 100%;
    }

    &__nav {
      &-outer {
        max-width: max-content;
        gap: 90px;
      }
    }

    &__low {
      position: relative;
      padding-bottom: 90px;
    }

    &__privacy {
      &-outer {
        position: absolute;
        bottom: 0;
      }
    }
  }
}
