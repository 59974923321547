.container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 75px;
}

.link {
  padding: 9px;
  border-radius: 38px;
  width: max-content;
  min-width: 38px;
  height: 38px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--dark);
  transition: color 0.2s linear, background 0.2s linear;

  cursor: pointer;

  @media (any-hover: hover) {
    &:hover {
      color: var(--light);
      background: var(--accent-hover);
    }
  }

  &:active {
    color: var(--light);
    background: var(--accent-active);
  }

  &__active {
    color: var(--light);
    background: var(--accent);
  }
}

@media(max-width: 1100px) {
  .link {
    font-size: 12px;
    font-weight: 700;
    border-radius: 27px;
    min-width: 27px;
    width: max-content;
    height: 27px
  }
}