.wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex: 1 0 auto;
  min-width: 412px;
}

.output {
  border-radius: 6px;
  border: var(--block-b);
  width: 100%;
}

.to,
.info,
.sum {
  padding: 20px;
}

.to {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-bottom: var(--block-b);
}

.btn {
  width: 100%;
  max-width: 100%;
}

.alert {
  pointer-events: none;
  border-radius: 8px;
  color: #5f5f5f;
  background: #f6f7fa;
  width: 100%;
  max-width: 100%;
  > span {
    max-width: 243px;
    font-size: 12px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 18px;
  border-bottom: var(--block-b);
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 18px;
  font-weight: 500;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.val {
  font-weight: 500;
}

.openVideo {
  &_outer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 40px 20px;
    background: #f6f7fa;
    border-radius: 8px;
    text-align: center;
  }

  &_sup {
    font-size: 12px;
    position: absolute;
    left: 50%;
    top: 20px;
    transform: translate(-50%, 0);
    color: #5F5F5F;

  }

  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 1025px) {
  .wrapper {
    width: 100%;
    min-width: 100%;
  }
}

@media (max-width: 1025px) {
  .btn {
    font-size: 12px;
  }

  .alert {
    padding: 9px 20px;
    > svg {
      width: 18px;
      height: 18px;
      flex-shrink: 0;
    }
    > span {
      font-size: 10px;
    }
  }

  .item {
    font-size: 12px;
  }

  .title {
    font-size: 14px;
  }

  .openVideo {
    &_outer {
      padding: 40px 15px;
    }
    &_sup {
      font-size: 10px;
      width: 100%;
    }
    font-size: 12px;
  }
}
