.catalog {
  &__head {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &-info {
      display: flex;
      flex-direction: column;
    }
  }

  &__finded {
    font-size: 20px;
    transform: translate(0, -50%);
  }
}

@media (max-width: 1025px) {
  .catalog {
    &__crumbs {
      display: none !important;
    }
    &__head {
      padding-top: 15px;

      &-btn {
        &-outer {
          display: flex;
          align-items: center;
          gap: 9px;
        }
        width: 54px;
        max-width: 54px;

        color: var(--accent);
        border-color: var(--light-gray);

        svg {
          stroke: none;
          fill: none;
        }

        &.active {
          color: var(--light);
        }
      }

      &-filter {
        position: relative;
        z-index: 1;
        background: #efefef;
        width: 100vw;
        height: 100dvh;

        display: flex;
        flex-direction: column;

        &-head {
          background: var(--light);
          padding: 12px;
          min-height: 60px;
          position: relative;
          display: flex;
          align-items: center;
        }

        &-title {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
        }

        &-close {
          font-size: 14px;
          font-weight: 400;
        }

        &-block {
          background: var(--light);
          padding: 15px 20px;

          &-outer {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding-top: 10px;
            height: calc(100dvh - 50px - 70px - 10px);
            overflow: auto;
            position: relative;
            z-index: 3;
          }
        }

        &-btn {
          max-width: 100%;
          width: 100%;
          &-outer {
            background: #efefef;
            z-index: 2;
            padding: 10px 12px 17px;
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 70px;
          }
        }

        &-link {
          display: flex;
          flex-direction: column;
          background: var(--light);
          padding: 0;
          width: 100%;
          padding: 15px;

          &-title {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 4px;
          }

          &-subtitle {
            font-size: 10px;
            font-weight: 400;
            color: var(--accent);
          }
        }
      }

      &-categories {
        background: var(--light);
        padding: 0;
        width: 100%;

        &-overlay {
          z-index: 3;
        }

        &-inner {
          position: relative;
        }

        &-head {
          background: var(--light);
          border-bottom: 1px solid #d9d9d9;
          padding: 12px;
          min-height: 60px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &-title {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 14px;
          }

          &-close {
            font-size: 14px;
            font-weight: 400;
          }
        }

        &-block {
          display: flex;
          justify-content: space-between;
          width: 100%;
          max-width: 100%;

          &-outer {
            overflow: auto;
            height: calc(100dvh - 60px);
          }
        }

        &-title {
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 4px;
        }

        &-subtitle {
          font-size: 10px;
          font-weight: 400;
          color: var(--accent);
        }
      }
    }

    &__title {
      margin-bottom: 5px;
      font-size: 16px;
    }

    &__finded {
      font-size: 10px;
      transform: translate(0, 0);
    }

    &__sort {
      &-overlay {
        z-index: 999;
      }

      position: relative;
      z-index: 1;
      background: var(--light);
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

      &-head {
        padding: 10px 15px;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-title {
        font-size: 16px;
      }

      &-item {
        &-outer {
          display: flex;
          flex-direction: column;
        }

        height: 48px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 25px;

        font-size: 14px;

        &:not(:last-child) {
          border-bottom: 1px solid var(--light-gray);
        }
      }
    }
  }
}
