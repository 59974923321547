@media (max-width: 1025px) {
  .sort {
    &__overlay {
      z-index: 999;
    }

    position: relative;
    z-index: 1;
    background: var(--light);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

    &__head {
      padding: 10px 15px;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      font-size: 16px;
    }

    &__item {
      &_outer {
        display: flex;
        flex-direction: column;
      }

      height: 48px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 25px;

      font-size: 14px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--light-gray);
      }
    }
  }
}
