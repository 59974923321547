.product {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  align-items: center;
}

.info_output {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.name {
  font-size: 16px;
  font-weight: 500;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;

  &_item {
    font-size: 14px;
    color: #5f5f5f;
  }
}

.output {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.price {
  font-size: 16px;
  font-weight: 500;
}

.date {
  font-size: 12px;
  color: #5f5f5f;
}

@media (max-width: 1025px) {
  .product {
    align-items: flex-start;
  }

  .info_output {
    flex-direction: column;
  }
}

@media (max-width: 1025px) {
  .product {
    gap: 10px;
  }

  .img {
    width: 60px;
    height: 60px;
  }

  .info_output {
    margin-top: 7px;
  }

  .info {
    gap: 4px;
    margin-bottom: 18px;
  }

  .name {
    font-size: 12px;
  }

  .list {
    gap: 2px;

    &_item {
      font-size: 10px;
    }
  }

  .output {
    gap: 2px;
  }

  .price {
    font-size: 12px;
  }

  .date {
    font-size: 11px;
  }
}
