.overlay {
  position: fixed;
  z-index: 100000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.20);
}

.modal {
  max-width: 500px;
  padding: 44px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 28px;

  background: var(--light);
  border-radius: 6px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.subtitle {
  color: #5f5f5f;
  font-size: 14px;
}

.btns {
  display: flex;
  gap: 12px;
}

.btn {
  padding: 16px 47px;
}
