.dropdown {
  z-index: 100;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  width: 258px;

  &.active {
    .control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      &__arrow {
        transform: translate(-50%,-50%) rotate(-180deg);
      }
    }

    .list {
      display: flex;
    }
  }
}

.control,
.list {
  border: var(--block-b);
  background: var(--light);
}

.control,
.item {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  padding-left: 22px;
}

.control {
  border-radius: 6px;
  &__text {
    font-size: 14px;
  }
  &__arrow {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.list {
  z-index: 10;
  position: absolute;
  top: 96%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 100%;
  border-top: unset;

  display: none;
  flex-direction: column;
}

.item {
  transition: background 0.2s linear;
  &.active {
    background: var(--light-gray);
    pointer-events: none;
  }

  @media (any-hover: hover) {
    &:hover {
      background: var(--light-gray);
    }
  }
}
