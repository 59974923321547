.rating {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 30px;
}

.stars {
  display: flex;
  align-items: center;
  gap: 4px;
}

.yellow {
    color: var(--yellow) !important;
}

.gray {
    color: var(--light-gray) !important;
}

.rate {
  color: var(--accent);
  font-size: 16px;
  font-weight: 400;
}
