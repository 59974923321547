.popular {
  &__img {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
  &__suptitle {
    margin-bottom: 4px;
    color: var(--gray);
  }

  &__title {
    margin-bottom: 14px;
  }

  &__text {
    margin-bottom: 30px;
    color: var(--gray);
    font-size: 11px;
    font-weight: 400;
    line-height: 140%;

    height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__btn {
    font-size: 12px;
    gap: 4px;
  }
}
