.modal {
  width: 80vw;
  height: 90vh;
  padding: 0;
  border-radius: 18px;
  overflow: hidden;
  background: var(--dark);
}

.inner {
  width: 100%;
  height: 100%;
}

.close {
  color: var(--light);
}

.playIco {
  width: 78px;
  height: 78px;
}

@media (max-width: 1025px) {
    .modal {
        width: 92vw;
        height: 75vw;
    }
  .playIco {
    width: 50px;
    height: 50px;
  }
}
