.seller {
  display: flex;
  align-items: center;
  gap: 16px;
}

.img {
  width: 58px;
  height: 58px;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name {
  font-size: 18px;
  font-weight: 400;
  color: var(--dark);
}

.rating {
  display: flex;
  align-items: center;
  gap: 8px;
}

.stars {
  display: flex;
  align-items: center;
  gap: 4px;
}

.rate {
  color: var(--accent);
  font-size: 16px;
  font-weight: 400;
}