html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
button,
a,
input {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

ul,
ol,
li {
  list-style: none;
}

button,
input {
  border: none;
  background: transparent;
  font-family: inherit;
}

button {
  cursor: pointer;
}

input,
textarea {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;

  outline: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

svg {
  fill: currentColor;
  transition: fill 0.2s linear, stroke 0.2s linear;

  &.stroke {
    fill: none;
    stroke: currentColor;
  }

  &.star {
    fill: #ffd335;
  }

  &.accent {
    stroke: var(--accent);
  }
}

section {
  margin-bottom: 120px;
}

html {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  height: 100%;
  font-family: var(--ff);
  font-size: 14px;
  color: var(--dark);
  background: var(--light);
  padding-top: 164px;
}

#root {
  height: 100%;
}

.site-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100%;
  overflow: hidden;
}

.main {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  position: relative;
  z-index: 2;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.title-hidden {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

@media (max-width: 1025px) {
  body {
    padding-top: 90px;
  }
  section {
    margin-bottom: 50px;
  }
}
