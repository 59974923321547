.hero {
  margin-bottom: 80px;
  &__top {
    display: flex;
    gap: 20px;
    height: 312px;
  }

  &__video-outer {
    max-width: 438px;
    width: 100%;
    height: inherit;
    background: var(--dark);
    border-radius: 15px;
    overflow: hidden;
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 6px;
    color: var(--light);

    position: relative;
    z-index: 1;

    border-radius: 10px;

    overflow: hidden;

    width: 100%;

    @media (any-hover: hover) {
      &:hover {
        .hero__block-bg {
          transform: scale(1.03);
        }
      }
      &:active {
        .hero__block-bg {
          transform: scale(1.02);
        }
      }
    }

    &-bg {
      position: absolute;
      z-index: -1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transition: transform 0.2s linear;
    }

    &-info {
      position: absolute;
      left: 80px;
      bottom: 40px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &-suptitle {
      font-size: 14px;
    }

    &-title {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &-block {
    width: 100%;
    height: 210px;
  }

  &__slider {
    &-outer {
      min-width: 0;
      width: 100%;
      height: inherit;
    }
    width: 100%;
    min-width: 0;
    height: inherit;
    .swiper-pagination {
      top: 30px !important;
      left: 50% !important;
      transform: translate(-50%, 0);
      position: absolute;
      display: flex;
      align-items: center;
      gap: 9px;
      bottom: unset !important;
      width: max-content !important;
      &-bullet {
        margin: 0 !important;
        width: 7px;
        height: 7px;

        background-color: var(--light-gray);
        border-radius: 7px;

        transition: width 0.5s linear, background-color 0.5s linear;
        &-active {
          width: 20px;
          background-color: var(--light);
        }
      }
    }
    &-nav-btn {
      background: transparent !important;
      color: var(--light) !important;
      position: absolute;
      top: 50%;
      z-index: 1;
      transition: background 0.2s linear, color 0.2s linear;
      &:hover {
        background: var(--light) !important;
        color: var(--dark) !important;
      }
      &.prev {
        left: 20px;
        transform: translate(0, -50%) rotate(-180deg) scale(0.8) !important;
      }

      &.next {
        right: 20px;
        transform: translate(0, -50%) scale(0.8) !important;
      }
    }
    &-slide {
      max-width: 100%;
    }
    &-content {
      position: relative;
      height: inherit;
      width: 100%;
      z-index: 1;
      border-radius: 12px;
      overflow: hidden;
      &:before {
        content: '';
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
      }
    }
    &-bg {
      z-index: 0;
    }
    &-info {
      z-index: 2;
      position: absolute;
      left: 50px;
      bottom: 64px;
      max-width: 392px;
      color: var(--light);
    }

    &-title {
      font-size: 48px;
      margin-bottom: 11px;
      font-weight: 500;
    }

    &-subtitle {
      margin-bottom: 30px;
      line-height: 160%;
    }

    &-link {
      padding-left: 50px;
      padding-right: 50px;
    }
  }

  &__deals {
    &-outer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      padding: 22px;
      border-radius: 4px;
      background: #f9f9f9;
    }

    &-suptitle {
      color: var(--search-ph);
      font-size: 16px;
    }

    display: flex;
    gap: 10px;
    align-items: center;

    &-ellipse {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      background: var(--accent);
      border-radius: 50%;
    }

    &-count {
      color: var(--dark);
      font-size: 20px;
      font-weight: 500;
    }
  }
}

@media (max-width: 1025px) {
  .hero {
    &__top {
      flex-direction: column;
      height: auto;
    }

    &__video {
      &-outer {
        width: 100%;
        max-width: 100%;
        height: 30vh;
      }
    }
    &__block {
      &-info {
        bottom: 21px;
        left: 17px;
        gap: 4px;
      }
      &-title {
        font-size: 14px;
      }

      &-suptitle {
        font-size: 10px;
      }
    }
    &__slider {
      .swiper-pagination {
        left: 17px !important;
        top: 17px !important;
        transform: unset !important;
      }
      &-nav {
        &-btn {
          display: none;
        }
      }

      &-content {
        height: 110px;
      }
    }
  }
}

@media (max-width: 1025px) {
  .hero {
    margin-bottom: 40px;
    &__block {
      &-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-width: 250px;
      }
    }

    &__deals {
      &-outer {
        padding: 11px;
      }

      &-suptitle {
        font-size: 12px;
      }

      &-ellipse {
        width: 9.333px;
        height: 9.333px;
      }

      &-count {
        font-size: 14px;
      }
    }
  }
}
