.outer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  text-align: center;
}

.title {
  font-size: 30px;
  font-weight: 500;
}

.subtitle {
  font-size: 14px;
  color: #5f5f5f;
}

@media (max-width: 1025px) {
  .outer {
    gap: 6px;
  }
  .title {
    font-size: 18px;
  }
  .subtitle {
    font-size: 12px;
    max-width: 160px;
  }
}
