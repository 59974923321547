.catalog__product {
  &-outer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }

  max-width: 320px;
  width: 100%;
}

@media (max-width: 1025px) {
  .catalog__product {
    &-outer {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: 8px;
    }
  }
}
