.outer {
  display: flex;
  flex-direction: column;
  gap: 38px;
  width: 100%;
}

.title {
  margin-bottom: 9px;
}

.subtitle {
  font-size: 12px;
}

@media (max-width: 1025px) {
  .outer {
    gap: 20px;
  }
  .title {
    font-size: 14px;
  }

  .subtitle {
    font-size: 12px;
  }
}
