.block {
  max-width: 580px;
  width: 100%;
}

.title {
  margin-bottom: 14px;
}

.subtitle {
  font-size: 16px;
  margin-bottom: 24px;
  max-width: 580px;
  width: 100%;
}

.form {
  border: unset;
  padding: 0;
  max-width: 100%;
}

.getNewCode {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

@media(max-width: 1025px) {
  .title {
    margin-bottom: 6px;
    font-size: 16px;
  }

  .subtitle {
    font-size: 12px;
    margin-bottom: 18px;
  }
}