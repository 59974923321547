.block {
  display: flex;
  justify-content: space-between;
  gap: 130px;
  margin-bottom: 30px;
}

.first {
  max-width: 430px;
  width: 100%;
}

.sec {
  width: 100%;
}

@media(max-width:1025px) {
  .block {
    flex-direction: column;
    gap: 40px;
  }

  .first {
    max-width: 100%;
  }
}