.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 26px;
}

.head {
  margin-bottom: 40px;
}
