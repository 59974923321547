.outer {
  border-radius: 6px;
  border: var(--block-b);
  color: var(--dark);
  overflow: hidden;
  transition: border-color .2s linear;
  @media(any-hover: hover) {
    &:hover {
      border: 1px solid var(--accent);
    }
  }
}

.info {
  display: flex;
  justify-content: space-between;
  padding: 24px 160px 24px 32px;
}

@media (max-width: 1025px) {
  .info {
    flex-direction: column;
    gap: 30px;
    padding: 20px;
  }
}
