.content {
  margin-top: 20px;
}

.items {
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
}

@media (max-width: 1025px) {
  .items {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}