.catalog {
  &__filter {
    width: 320px;
    flex-shrink: 0;
  }

  &__perPage {
    max-width: 87px;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 25px;
  }

  &__category {
    &-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &-sublist {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-left: 12px;
      margin-top: 12px;
    }

    &-all {
      margin-bottom: 4px;
      font-weight: 500;
      &.active {
        color: var(--accent-active);
        pointer-events: none;
      }
    }

    &-link {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-weight: 500;
      &:before {
        content: '';
        width: 8px;
        height: 8px;
        flex-shrink: 0;
        border-radius: 50%;
        margin-right: 12px;
        background: var(--light-gray);
      }

      &.active {
        color: var(--accent);
        pointer-events: none;
        &:before {
          background: var(--accent);
        }
      }
    }

    justify-content: flex-start;
    cursor: pointer;
    color: var(--accent);

    @media (any-hover: hover) {
      &:hover {
        color: var(--accent-hover);
      }
    }

    &:active,
    &.active {
      color: var(--accent-active);
    }

    &-text {
      font-weight: 500;
    }

    &-count {
      margin-left: 6px;
      color: var(--search-ph);
    }
  }
}

@media (max-width: 1025px) {
  .catalog {
    &__content {
      flex-direction: column;
    }
  }
}
