.logo {
  color: var(--accent);
  width: 142px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.15s linear;
  flex-shrink: 0;
  svg,
  img {
    fill: currentColor;
    object-fit: contain;
    width: inherit;
    height: inherit;
    flex-shrink: 0;
  }

  @media (any-hover: hover) {
    &:hover {
      color: var(--accent-hover);
    }
  }

  &:active {
    color: var(--accent-active);
  }

  &-seller {
    gap: 10px;
    width: auto;
    height: auto;
    > svg {
      width: 147px;
      height: auto;
    }
    > span {
      font-size: 18px;
      font-weight: 500;
      color: var(--dark);
      transform: translate(0, -1.5px);
    }
  }
}

.flex {
  display: flex;
  &-col {
    flex-direction: column;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

.gap {
  &-4 {
    gap: 4px;
  }
  &-8 {
    gap: 8px;
  }
  &-12 {
    gap: 12px;
  }
  &-14 {
    gap: 14px;
  }
  &-16 {
    gap: 16px;
  }
  &-20 {
    gap: 20px;
  }
  &-24 {
    gap: 24px;
  }
  &-30 {
    gap: 30px;
  }
}

.items {
  &-center {
    align-items: center;
  }
}

.justify {
  &-between {
    justify-content: space-between;
  }
}

.title {
  font-weight: 500;

  &-main {
    font-size: 48px;
    line-height: 120%;
    font-weight: 500;
  }

  &-section {
    font-size: 30px;
    line-height: normal;
    font-weight: 500;
  }

  &-ico {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 20px;
    font-weight: 500;
  }

  &-seller {
    font-size: 24px;
    margin-bottom: 16px;
  }

  &-block {
    font-size: 18px;
    margin-bottom: 20px;
  }

  &-modal {
    font-size: 20px;
    margin-bottom: 8px;
  }
}

.subtitle {
  &-modal {
    color: #5f5f5f;
  }
}

.list {
  &-disc {
    list-style: disc;
    &__item {
      list-style: disc;
      margin-left: 20px;
    }
  }
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  line-height: normal;
  font-weight: 400;

  border-radius: 8px;

  background: var(--light);
  color: var(--dark);

  padding: 16px 29px 17px 29px;
  max-width: max-content;

  transition: background 0.15s linear, color 0.15s linear,
    border-color 0.15s linear;

  @media (any-hover: hover) {
    &:hover {
      background: var(--accent-hover);
      color: var(--light);
    }
  }

  &:active {
    background: var(--accent-active);
    color: var(--light);
  }

  &.dis {
    background: var(--el-dis-bg);
    border-color: var(--el-dis-bg);
    color: var(--el-dis-c);
    pointer-events: none;
  }

  &-accent {
    background: var(--accent);
    color: var(--light);
    font-weight: 500;
  }

  &-cancel {
    background: #f6f7fa;
    color: #337bf9;
    font-weight: 500;
  }

  &-red {
    background: var(--red);
    color: var(--light);
    font-weight: 500;

    @media (any-hover: hover) {
      &:hover {
        background: var(--red-hover);
      }
    }

    &:active {
      background: var(--red-active);
    }
  }

  &-ico {
    width: 42px;
    height: 40px;
    flex-shrink: 0;

    padding: 12px 13px;

    border: 1px solid var(--accent);
    border-radius: 6px;
    svg {
      fill: var(--light);
      stroke: var(--accent);
    }

    @media (any-hover: hover) {
      &:hover {
        svg {
          fill: transparent;
          stroke: var(--light);
        }
      }
    }

    &.dis {
      border-color: var(--el-dis-bg);

      svg {
        fill: var(--el-dis-bg);
        stroke: var(--el-dis-c);
      }
    }
  }

  &-border {
    border: 1px solid var(--accent);
    color: var(--accent);
    &.active {
      background: unset;
      color: var(--dark);
      border-color: var(--accent);
      pointer-events: none;
    }
  }

  &-slider {
    width: 50px;
    height: 50px;
    flex-shrink: 0;

    padding: 16px;

    border: 1px solid currentColor;
    color: var(--dark);
    border-radius: 50%;
    transition: color 0.15s linear;
    svg {
      fill: none;
      stroke: currentColor;
    }

    @media (any-hover: hover) {
      &:hover {
        background: transparent;
        color: var(--accent-hover);
      }
    }

    &:active {
      background: transparent;
      color: var(--accent-active);
    }

    &.dis {
      color: var(--el-dis-bg);
      background: transparent;
    }

    &.prev {
      transform: rotate(-180deg);
    }
  }

  &-lazur {
    color: var(--accent);
    background: rgba(#337bf9, 0.1);

    @media (any-hover: hover) {
      &:hover {
        background: rgba(#337bf9, 0.2);
        color: var(--accent-hover);
      }
    }
  
    &:active {
      background: rgba(#337bf9, 0.3);
      color: var(--accent-active);
    }
  }
}

.ico {
  &-80 &-30,
  &-24,
  &-20,
  &-18,
  &-16,
  &-14,
  &-12,
  &-10 {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.fill {
      fill: currentColor;
    }

    &.stroke {
      stroke: currentColor;
    }
  }

  &-80 {
    width: 80px;
    height: 80px;
  }

  &-30 {
    width: 30px;
    height: 30px;
  }

  &-24 {
    width: 24px;
    height: 24px;
  }

  &-20 {
    width: 20px;
    height: 20px;
  }

  &-18 {
    width: 18px;
    height: 18px;
  }

  &-16 {
    width: 16px;
    height: 16px;
  }

  &-14 {
    width: 14px;
    height: 14px;
  }

  &-14 {
    width: 12px;
    height: 12px;
  }

  &-10 {
    width: 10px;
    height: 10px;
  }
}

.link {
  color: var(--dark);
  transition: color 0.15s linear, background 0.15s linear,
    border-color 0.15s linear;

  font-size: 14px;
  font-weight: 500;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  @media (any-hover: hover) {
    &:hover {
      color: var(--accent-hover);
    }
  }

  &:active {
    color: var(--accent-active);
  }

  &.dis {
    color: var(--el-dis-bg);
    pointer-events: none;
  }

  &-accent {
    color: var(--accent);
    font-size: inherit;
    font-weight: 500;

    @media (any-hover: hover) {
      &:hover {
        color: var(--accent-hover);
      }
    }

    &:active {
      color: var(--accent-active);
    }

    &-a {
      color: var(--accent);
    }
  }

  &-red {
    color: var(--red);

    @media (any-hover: hover) {
      &:hover {
        color: var(--red-hover);
      }
    }

    &.active {
      color: var(--red-active);
    }
  }

  &-ico {
    border-radius: 50%;
    padding: 10px;
    background: #efefef;
    color: var(--accent);

    @media (any-hover: hover) {
      &:hover {
        background: var(--accent-hover);
        color: var(--light);
      }
    }

    &:active {
      background: var(--accent-active);
      color: var(--light);
    }

    &.dis {
      background: var(--el-dis-bg);
      color: var(--light);
    }
  }

  &-brsys {
    color: var(--brsys-c);
    font-weight: 500;

    svg {
      fill: none;
      stroke: currentColor;
      stroke-width: 2px;
      width: 99px;
      height: 17px;
      transform: translate(0, 4px);
    }

    @media (any-hover: hover) {
      &:hover {
        color: var(--brsys-c-h);
      }
    }

    &:active {
      color: var(--brsys-c-a);
    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding: 64px 60px;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  border: 1px solid var(--light-gray);
  background: transparent;

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: 16px;
    margin-bottom: 20px;
  }

  &__submit {
    width: 100%;
    max-width: 100%;
  }
}

.checkbox-radio {
  display: flex;
  cursor: pointer;
  user-select: none;
  margin-right: 16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:first-child {
    margin-top: 50px;
  }

  &:last-child {
    margin-bottom: 50px;
  }

  @media (any-hover: hover) {
    &:hover {
      & .checkbox-radio {
        &-custom {
          border-color: var(--accent-hover);
        }
      }
    }

    &:active {
      & .checkbox-radio {
        &-custom {
          border-color: var(--accent-active);
        }
      }
    }
  }
  & input {
    width: 25px;
    height: 25px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }

  &-custom {
    flex-shrink: 0;
    width: 25px;
    height: 25px;
    border: 2px solid var(--light-gray);
    border-radius: 4px;
    margin-right: 8px;
    position: relative;
    transition: border-color 0.2s linear;
  }

  &-custom svg {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease-in-out;
  }

  & input:checked + &-custom {
    border-color: var(--accent);
  }

  & input:checked + &-custom svg {
    transform: translate(-50%, -50%) scale(1);
  }

  /* Настройте стили для radio-кнопок, добавив нужные стили */
  &.radio &-custom svg {
    border-radius: 50%;
  }

  &-text {
    display: inline;
  }

  &-link {
    display: inline;
    font-weight: 400;
  }
}

.input {
  display: flex;
  align-items: center;
  width: 100%;

  padding: 17px 28px;
  border-radius: 6px;
  border: 2px solid var(--search-bg);
  color: var(--dark);
  background: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  transition: border-color 0.2s linear;

  &::placeholder {
    color: var(--search-ph);
  }

  @media (any-hover: hover) {
    &:hover {
      border-color: var(--accent);
    }
  }

  &:focus {
    border-color: var(--accent);
  }
}

.textarea {
  display: flex;
  align-items: center;
  width: 100%;
  resize: none;

  padding: 17px 28px;
  border-radius: 6px;
  border: 2px solid var(--search-bg);
  font-family: var(--ff);
  color: var(--dark);
  background: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  transition: border-color 0.2s linear;

  &::placeholder {
    color: var(--search-ph);
  }

  @media (any-hover: hover) {
    &:hover {
      border-color: var(--accent);
    }
  }

  &:focus {
    border-color: var(--accent);
  }
}

.search {
  &-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--search-bg);
    border-radius: 8px;
    width: 100%;
  }

  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  &-ico {
    color: var(--search-ph);
    stroke: currentColor;
    fill: none;
    position: absolute;
    left: 12px;
    pointer-events: none;
  }

  &-field {
    padding: 18px 12px 18px 42px;
    font-weight: 500;
    width: 100%;
    &::placeholder {
      font-weight: 500;
      color: var(--search-ph);
    }

    &:before {
      color: var(--search-ph);
    }
  }

  &-letter {
    color: var(--search-ph);
    margin-right: 10px;
  }

  &-sort {
    &-overlay {
      z-index: -1;

      position: fixed;
      height: 100vh;
      width: 100vw;
      left: 0;
      top: 0;

      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      &.active {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
    &-outer {
      position: relative;
      z-index: 1;
    }
    &-btn {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 18px 25px;
      background: var(--search-bg);
      min-width: 140px;

      &.active {
        background: var(--bar-nav-bg);
        color: var(--light);

        svg {
          transform: rotate(270deg);
        }
      }

      @media (any-hover: hover) {
        &:hover {
          background: var(--bar-nav-bg);
        }
      }

      &:active {
        background: var(--bar-nav-btn-bg-a);
      }

      svg {
        stroke: currentColor;
        fill: none;
        transform: rotate(90deg);
        transition: transform 0.2s linear;
      }
    }

    &-list {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 100%;
      transform: translate(-50%, 10px);
      display: flex;
      flex-direction: column;
      background: var(--search-bg);
      border-radius: 8px;

      opacity: 0;
      pointer-events: none;
      visibility: hidden;

      &.active {
        opacity: 1;
        pointer-events: all;
        visibility: visible;
      }

      &-item {
        width: 100%;
        max-width: 100%;
        background: var(--search-bg);
        cursor: pointer;

        &.active {
          background: var(--bar-nav-bg);
          color: var(--light);
          pointer-events: none;
        }
      }
    }
  }
}

.slider {
  box-sizing: border-box;
  min-width: 0;

  &-section {
    &__high {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 40px;
      border-bottom: var(--section-b);
      margin-bottom: 42px;
    }

    &__title {
      &-outer {
        display: flex;
        gap: 50px;
        align-items: center;
      }
    }

    &__subtitle {
      max-width: 180px;
      margin-top: 16px;
    }

    &__more {
      font-size: 12px;
      &-mob {
        display: none;
      }
    }
  }

  &-pagination {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 9px;
    bottom: unset !important;
    width: max-content !important;

    .swiper-pagination {
      &-bullet {
        margin: 0 !important;
        width: 7px;
        height: 7px;

        background-color: var(--light-gray);
        border-radius: 7px;

        transition: width 0.5s linear, background-color 0.5s linear;
        &-active {
          width: 20px;
          background-color: var(--light);
        }
      }
    }
  }

  &-nav {
    display: flex;
    gap: 24px;
    position: absolute;
    right: 350px;
  }

  &-slide {
    box-sizing: border-box;
    @media (any-hover: hover) {
      &:hover {
        .slider-link {
          border-color: var(--accent-hover);
        }
      }
    }

    &:active {
      border-color: var(--accent-active);
    }
  }

  &-link {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    color: var(--dark);
    border: var(--block-b);
    transition: border-color 0.15s linear;
  }

  &__title {
    font-size: 18px;
    line-height: normal;
    font-weight: 400;
    min-height: 46px;
    margin-bottom: 10px;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-bar {
    display: flex;
    align-items: flex-start;
    gap: 9px;
    justify-content: space-between;
  }

  &-btn {
    &:not(.btn-ico) {
      width: 100%;
      max-width: 100%;
      padding: 12px 0;
    }
    height: auto;
  }
}

.w {
  &-full {
    width: 100%;
    max-width: 100%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.2s linear, visibility 0.2s linear,
    pointer-events 0.2s linear;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.yellow {
  color: var(--yellow);
}

.scrollbar {
  overflow: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 115px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--light-gray);
    border-radius: 4px;
  }
}

@media (max-width: 1025px) {
  .search {
    width: 100%;
    &-outer {
      width: 100%;
    }
    &-ico {
      width: 13px;
      height: 13px;
      left: 8px;
    }
    &-letter {
      display: none;
    }
    &-sort {
      &-outer {
        display: none;
      }
    }

    &-field {
      max-width: 100%;
      width: 100%;
      font-size: 12px;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 29px;
    }
  }

  .slider {
    &-nav {
      display: none;
    }
  }
}

@media (max-width: 1025px) {
  .title {
    &-section {
      font-size: 24px;
    }
  }

  .slider {
    &-section {
      &__high {
        border-bottom: unset;
        padding-bottom: 0;
        margin-bottom: 20px;
      }

      &__subtitle {
        display: none;
      }

      &__more {
        display: none;

        &-mob {
          margin-top: 30px;
          display: flex;
          max-width: 100%;
          width: 100%;
        }
      }
    }

    &-link {
      padding: 14px;
    }

    &-bar {
      flex-wrap: wrap;
    }

    &-btn {
      max-width: calc(50% - 8px);
      width: calc(50% - 8px);
      font-size: 10px;
      gap: 4px;
      padding: 10.5px;
    }
  }

  .form {
    padding: 20px 28px;

    &__title {
      font-size: 16px;
      margin-bottom: 8px;
    }

    &__subtitle {
      font-size: 12px;
      margin-bottom: 37px;
      max-width: 250px;
      display: inline;

      &-inner {
        display: inline;
      }
    }
  }

  .checkbox-radio {
    &:not(:last-child) {
      margin-bottom: 26px;
    }

    &:first-child {
      margin-top: 45px;
    }

    &:last-child {
      margin-bottom: 20px;
    }

    & input,
    &-custom {
      width: 20px;
      height: 20px;
    }

    &-custom {
      svg {
        width: 12px;
        height: 12px;
      }
    }

    &-text {
      font-size: 12px;
    }
  }
}

.z {
  &-0 {
    z-index: 0 !important;
  }
  &-9991 {
    z-index: 9991 !important;
  }

  &-9999 {
    z-index: 9999 !important;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}