.actions {
  display: flex;
  align-items: center;
  gap: 28px;
  padding: 17px;
  border-bottom: var(--block-b);
}

.check,
.del {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0;
}

.del {
  &__dis {
    pointer-events: none;
    color: var(--light-gray);
  }
}

@media (max-width: 1025px) {
  .actions {
    justify-content: space-between;
    padding: 15px;
  }

  .del {
    font-size: 12px;
  }
}
