.head,
.top,
.bot,
.list {
  display: flex;
  flex-direction: column;
}

.head {
  margin-bottom: 36px;

  gap: 24px;
}

.top {
  gap: 22px;
}

.bot {
  gap: 8px;
}

.img {
  margin: 0 auto;
  width: 140px;
  height: 140px;
  flex-shrink: 0;

  border-radius: 50%;
  object-fit: cover;
}

.name {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.list {
  gap: 6px;
  &_item {
    font-size: 14px;
  }
}

.status {
  font-size: 14px;
}

.btn {
  font-size: 14px;
  border-radius: 71px;
  padding: 8px 20px;
}

@media (max-width: 1025px) {
  .top {
    align-items: center;
    flex-direction: row;
    gap: 20px;
  }

  .bot {
    gap: 8px;
  }

  .img {
    margin: 0;
  }

  .btn {
    width: 100%;
    max-width: 100%;
  }
}
