.head {
  margin-bottom: 28px;
}

.title {
  margin-bottom: 40px;
}

@media (max-width: 1025px) {
  .title_sort {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    margin-bottom: 0;
  }
}
