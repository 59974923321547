.overlay {
    padding: 0;
    top: 70px;
}

.modal {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 150px;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 14px;

    &_link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #808a9c;
        font-size: 16px;

        &_active {
            color: var(--accent);
        }
    }
}

.bot {
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.contacts {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    gap: 25px;
    &_link {
        color: #808a9c;
    }
}