.item {
  padding: 20px 0;
  border-radius: 16px;
  border: 1px solid #d9d9d9;

  cursor: pointer;
  transition: border-color 0.15s linear, background 0.15s linear;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  @media (any-hover: hover) {
    &:hover {
      border-color: var(--accent);
    }
  }

  &__active {
    border-color: #d9d9d9;
    & .head {
      margin-bottom: 20px;
      &__title {
        font-weight: 500;
      }
      &__ico {
        transform: rotate(-45deg);
      }
    }
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  padding: 0 36px;

  font-family: var(--onest);
  color: inherit;

  transition: margin-bottom 0.15s linear;

  &__title {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
  }

  &__ico {
    transition: transform 0.15s linear;
    color: var(--accent);
  }
}

.content {
  padding: 0 60px 0 36px;
  overflow: hidden;
  &__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
  }
}

@media (max-width: 1025px) {
  .item {
    padding: 20px 0;
    &__active {
      & .head {
        margin-bottom: 17px;
        &__ico {
          color: var(--accent);
        }
      }
    }
  }
  .head {
    padding: 0 20px;
    &__title {
      max-width: 70%;
      font-size: 12px;
    }

    &__ico {
      width: 25px !important;
      height: 25px !important;

      transition: color 0.2s linear, transform 0.2s linear;
    }
  }

  .content {
    padding: 0 20px;
    &__text {
      font-size: 10px;
      line-height: 120%;
    }
  }
}
