.info {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 112px;
  font-size: 16px;
}

.top {
  display: flex;
  gap: 34px;
}

.name {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 10px;
}

.img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.bot {
  position: absolute;
  left: 174px;
  top: 121px;

  display: flex;
  gap: 74px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rating {
    margin-bottom: 0;
}

@media (max-width: 1025px) {
  .info {
    font-size: 14px;
    margin-bottom: 54px;
  }
  .top {
    gap: 18px;
    margin-bottom: 30px;
  }
  .img {
    width: 60px;
    height: 60px;
  }
  .name {
    font-size: 18px;
  }
  .bot {
    position: static;
    flex-direction: column;
    gap: 24px;
  }
}
